<template>
  <div class="opacity-50">
    <svg class="absolute right-full hidden translate-x-1/2 translate-y-[1000px] transform lg:block" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">
      <defs>
        <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" class="text-blue-base" fill="currentColor"></rect>
        </pattern>
      </defs>
      <rect width="300" height="1300" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"></rect>
    </svg>
  </div>
  <div class="md:mb-32 mb-10 md:mt-44 mt-32">
    <h1 class="text-center text-white font-extrabold text-5xl  ">À propos de Telecom Nancy Services</h1>
    <hr class="w-48 h-1 mx-auto mt-4 bg-blue-base border-0 rounded md:mt-10">
  </div>

  <div class="pt-16 lg:py-24 lg:pb-36">
    <div class="bg-blue-base pb-16 pt-10 md:pt-0 lg:relative lg:z-10 lg:pb-0">
      <div class="lg:mx-auto lg:grid lg:max-w-8xl lg:grid-cols-3 lg:gap-8 lg:px-8">
        <div class="relative lg:-my-8">
          <div class="mx-auto max-w-md px-6 sm:max-w-3xl lg:h-full lg:p-0">
            <div class="aspect-w-10 aspect-h-6 overflow-hidden rounded-xl shadow-xl sm:aspect-w-24 sm:aspect-h-7 lg:aspect-none lg:h-full">
              <img class="object-contain lg:h-full lg:w-full" src="@/assets/telecom_nancy_tns.jpg" alt="" />
            </div>
          </div>
        </div>
        <div class="mt-12 lg:col-span-2 lg:m-0 lg:pl-8 pr-0 md:pr-16">
          <div class="mx-auto max-w-md px-6 sm:max-w-2xl lg:max-w-none lg:px-0 lg:py-20">
            <blockquote>
              <div>
                <svg class="h-12 w-12 text-white opacity-25" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p class="mt-6 text-2xl font-medium text-white">TELECOM Nancy Services est une association de loi 1901  existant depuis 1994, prestataire de services dans le domaine de l’informatique et de ses applications. TELECOM Nancy est une école d’ingénieur habilitée par la Commission des Titres d’Ingénieur (CTI), gage de qualité des enseignements qui y sont dispensés.<br><br>Orienté vers le monde du numérique, TELECOM Nancy propose une formation pluridisciplinaire dans les domaines de la Cybersécurité, de l’Ingénierie Logiciel, des Systèmes Embarqués, du Big Data et de l’Intelligence Artificielle ainsi que dans les Systèmes d’Informations d’Entreprise.</p>
              </div>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-black-base">
    <div class="mx-auto max-w-7xl py-12 px-6 text-center bg-gray-200 rounded-lg m-10 lg:px-8 lg:py-24">
      <div class="space-y-8 sm:space-y-12">
        <div>
          <h1 class="text-center font-bold text-3xl md:mb-0 mb-5">Les membres de l'équipe TNS</h1>
          <hr class="w-48 h-1 mx-auto my-4 bg-blue-base border-0 rounded md:my-10">
        </div>
        <ul role="list" class="mx-auto grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-6">
          <li v-for="person in people" :key="person.name">
            <div class="space-y-4">
              <div class="relative">
                <div v-if="person.linkedinUrl" class="absolute left-0">
                  <a target="_blank" :href="person.linkedinUrl" class="text-gray-400 hover:text-blue-linkedin">
                    <span class="sr-only">LinkedIn</span>
                    <svg class="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                      <path fill-rule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clip-rule="evenodd" />
                    </svg>
                  </a>
                </div>
                <img class="mx-auto h-20 w-20 rounded-full lg:h-24 lg:w-24" :src="person.imageUrl" alt="" />
              </div>
              <div class="space-y-2">
                <div class="text-xs font-medium lg:text-sm">
                  <div>
                    <h3>{{ person.name }}</h3>
                  </div>
                  <p class="text-blue-base">{{ person.role }}</p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script setup>
const people = [
{
    name: 'Oscar LAHAIE',
    role: 'Président',
    imageUrl: '/assets/images/members/oscar.png',
    linkedinUrl: ''
  },
 
  {
    name: 'Anaé DESIGAUX',
    role: 'Secrétaire Général',
    imageUrl: '/assets/images/members/anae.png',
    linkedinUrl: 'https://www.linkedin.com/in/ana%C3%A9-desigaux-69115b334/'
  },

 { 
   name: "Quentin CORNIER",
   role: "Chef de projet",
   imageUrl: '/assets/images/members/quentin.png',
   linkedinUrl: 'https://www.linkedin.com/in/quentin-cornier/'
 },

 { 
   name: "Salif VÉRET",
   role: "Chef de projet",
   imageUrl: '/assets/images/members/salif.png',
   linkedinUrl: 'https://www.linkedin.com/in/salif-v%C3%A9ret-ab8a23181/'
 },
  
  {
    name: 'Anne-Cécile POISOT',
    role: 'D.S.I. et Chef de projet',
    imageUrl: '/assets/images/members/anne-cecile.png',
    linkedinUrl: 'https://www.linkedin.com/in/anne-c%C3%A9cile-poisot-363584347/'
  },

  {
    name: 'Teresa FERNANDEZ DEL BUSTO',
    role: 'Trésorier',
    imageUrl: '/assets/images/members/teresa.png',
    linkedinUrl: 'https://www.linkedin.com/in/teresa-fernandez-del-busto-jimenez-9275232b0/'
  },

  {
    name: "Eléa-Rose THOMAS",
    role: "Communication",
    imageUrl: '/assets/images/members/elea-rose.png',
    linkedinUrl: 'https://www.linkedin.com/in/el%C3%A9a-rose-thomas-7680b4340/'
    },

  
   {
    name: "Dorian GENESTE",
    role: "Responsable R. H.",
    imageUrl: '/assets/images/members/dorian.png',
    linkedinUrl: ''
    },
    
    
  // More people...

  // -------------------------------------
  // -- Merci de laisser les membres du Comité d'Orientation Stratégique
  // -- d'un mandat à l'autre ci-dessosu
  // -------------------------------------


  //{
  //  name: "Serge GUILLEMART",
  //  role: "Secrétaire Général du Comité d'Orientation Stratégique",
  //  linkedinUrl: "",
  //  imageUrl: "/assets/images/members/SergeGuillemart.jpg"
  //},
  //{
  //  name: "Léo WERNER",
  //  role: "Membre du Comité d'Orientation Stratégique",
  //  linkedinUrl: "",
  //  imageUrl: "/assets/images/members/LeoWerner.png"
  //},
  //{
  //  name: "Benjamin OCHIER",
  //  role: "Membre du Comité d'Orientation Stratégique",
  //  linkedinUrl: "",
  //  imageUrl: "/assets/images/members/BenjaminOchier.jpg"
  //}



]
</script>

<script>
import Membres from '@/components/Membres.vue';

export default {
  name: 'About',
  components: {
    Membres
  },
  mounted() {
    // scroll to top with smooth transition
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
</script>
